
import client from "./requestclient";

// Export an object with methods to fetch smart guarantors
export const requestForSmartGuarantors = {
  fetchSmartGuarantors: async () => {
    try {
      // Use the client function to make a GET request to the API
      const response = await client({ URL_PATH: "requestforsmartguarantors", method: "GET" });

      // Assuming the API response contains the smart guarantors data
      return response.data; // Adjust this based on your API response structure
    } catch (error) {
      // Handle errors
      console.error("Error fetching smart guarantors:", error);
      throw error; // Propagate the error
    }
  },
};

