<template>
    <div class="tenant-container">
      <div class="tenant-header">
        <h3 class="header-text">All Requests</h3>
      </div>
  
      <ul role="list" class="">
        <li v-for="(smartGuarantor, index) in smartGuarantors" :key="index" class="frame-container">
          <div :class="getFrameClass(smartGuarantor.status)">
            <div class="frame-content">
              <div class="frame-top">
                <span class="name-text"> {{ smartGuarantor.firstname + ' ' + smartGuarantor.lastname }}</span>
               
                <div :class="getFrameBadgeClass(smartGuarantor.status)">
                  <div :class="getFrameBadgeBaseClass(smartGuarantor.status)">
                    <span :class="getFrameBadgeTextClass(smartGuarantor.status)">
                      {{ smartGuarantor.status }}
                    </span>
                  </div>
                </div>
              </div>
              <p class="frame-location">{{ smartGuarantor.location }}</p>
              <!-- <div class="frame-table-cell">
                <div class="frame-progress-bar">
                  <span class="frame-progress-text">PI</span>
                  <div class="frame-progress-bar-inner">
                   
                    <div class="frame-progress-background" v-bind:style="{'width':smartGuarantor.progressPercent + '%'}"></div>
                  </div>
                  <span class="frame-progress-label">0%</span>
                </div>
              </div> -->
            </div>
            <span class="frame-view-details">View details</span>
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  
  import { requestForSmartGuarantors } from "../services/requestForSmartGuarantors";
  
  export default {
    name: "TenantRequest",
 
    data() {
      return {
        smartGuarantors: [ ],
      };
    },
    mounted() {
      // Fetch smart guarantors data when the component is mounted
      this.fetchSmartGuarantors();
    },
    methods: {
      // Function to fetch smart guarantors data
      async fetchSmartGuarantors() {
        try  {
          // Call the fetchSmartGuarantors function from the service
          const data = await requestForSmartGuarantors.fetchSmartGuarantors();
          
    
  
          if (data && Array.isArray(data)) {
        // Extract only the required properties from each object
        const extractedData = data.map(entry => {
          let location = entry.location;
          // Check if location has a comma
          if (location && location.includes(',')) {
            location = location.split(',').slice(1).join(',').trim();
          } else {
            // If no comma, use the last 30 characters
            location = location ? location.slice(-30) : 'Address needs to be updated';
          }

          return {
            firstname: entry.firstname.charAt(0).toUpperCase() + entry.firstname.slice(1).toLowerCase(),
            lastname: entry.lastname.charAt(0).toUpperCase() + entry.lastname.slice(1).toLowerCase(),
            status: entry.status,
            location,
            id: entry.id,
          };
        });

        // Update the component's data with the extracted data
        this.smartGuarantors = extractedData;
      } else {
            console.error("Invalid data structure from API");
          }
        } catch (error) {
          console.error("Error fetching smart guarantors:", error);
        }
      },
  
  
      getFrameClass(status) {
        return `frame frame${this.getStatusNumber(status)}`;
      },
      getFrameBadgeClass(status) {
        return `frame-badge frame${this.getStatusNumber(status)}-badge`;
      },
      getFrameBadgeBaseClass(status) {
        return `frame${this.getStatusNumber(status)}-frame-badgebase`;
      },
      getFrameBadgeTextClass(status) {
        return `frame${this.getStatusNumber(status)}-text${this.getStatusNumber(
          status
        )}`;
      },
      getStatusNumber(status) {
        switch (status) {
          case "pending":
            return "1000004255";
          case "Accepted":
            return "1000004255";
          case "Rejected":
            return "1000004255";
          default:
            return "";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .tenant-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    flex-direction: column;
  }
  
  .tenant-header {
    gap: 16px;
    width: 100%;
    height: auto;
    display: flex;
    padding: 20px;
    align-items: center;
    background-color: white;
    margin-top: 10px;
  }
  
  .content-text {
    color: rgba(43, 51, 82, 1);
    height: auto;
    flex-grow: 1;
    font-size: 18px;
    font-style: Medium;
    text-align: left;
    font-family: Lato;
    font-weight: 500;
    line-height: 28px;
    font-stretch: normal;
    text-decoration: none;
    padding-left: 4px;
  }
  
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
    margin-top: 1.25rem;
    padding-right: 0.5rem;
  }
  
  @media (min-width: 768px) {
    ul {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media (min-width: 1024px) {
    ul {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding-right: 0;
    }
  }
  
  .frame-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .frame {
    gap: 24px;
    width: 100%;
    height: auto;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 20px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  
  .frame-content {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .frame-top {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  
  .name-text {
    color: rgba(85, 92, 116, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Lato;
    font-weight: 400;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  
 
  
  .frame-badge {
    display: flex;
    align-items: flex-start;
  }
  
  .frame-location {
    color: rgba(85, 92, 116, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Lato;
    font-weight: 400;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .frame-table-cell {
    gap: 12px;
    display: flex;
    padding: 8px 0;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(234, 236, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  
  .frame-progress-bar {
    gap: 12px;
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
  }
  
  .frame-progress-text {
    color: var(--dl-color-gray-700);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Lato;
    font-weight: 500;
    line-height: 20px;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .frame-progress-bar-inner {
    height: 8px;
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #f2f4f7;
  }
  
  .frame-progress-background {
    height: 8px;
    float: left;
    border-radius: 4px;
    background-color: blue;
  }
  
  .frame-progress-indicator {
    top: 0px;
    left: 0px;
    width: 153px;
    height: 8px;
    position: absolute;
    border-radius: 4px;
  }
  
  .frame-progress-label {
    color: var(--dl-color-gray-700);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Lato;
    font-weight: 500;
    line-height: 20px;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .frame-view-details {
    color: rgba(0, 51, 233, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    text-align: left;
    font-family: Lato;
    font-weight: 500;
    line-height: 20px;
    font-stretch: normal;
    text-decoration: none;
    cursor: pointer;
  }
  
  .frame-pending {
    background-color: rgb(239, 230, 230);
  }
  
  .frame-accepted {
    background-color: rgb(46, 147, 18);
  }
  
  .frame-rejected {
    background-color: rgb(237, 10, 59);
  }
  
  /* Pending */
  .frame1000004255-badge {
    display: flex;
    align-items: flex-start;
  }
  
  .frame1000004255-frame-badgebase {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 14px;
    justify-content: center;
    background-color: rgba(245, 245, 247, 1);
  }
  
  .frame1000004255-text02 {
    color: rgba(85, 92, 116, 1);
    height: auto;
    font-size: 10px;
    font-style: Medium;
    text-align: center;
    font-family: Lato;
    font-weight: 300;
    line-height: 18px;
    font-stretch: normal;
  }
  
  /* Accepted */
  .frame1000004255-badge1 {
    display: flex;
    align-items: flex-start;
  }
  
  .frame1000004255-frame-badgebase1 {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    background-color: rgb(44, 135, 19);
  }
  
  .frame1000004255-text14 {
    color: rgba(42, 195, 0, 1);
    height: auto;
    font-size: 10px;
    font-style: Medium;
    text-align: center;
    font-family: Lato;
    font-weight: 500;
    line-height: 18px;
    font-stretch: normal;
    text-decoration: none;
  }
  
  /* Rejected */
  .frame1000004255-badge2 {
    display: flex;
    align-items: flex-start;
  }
  
  .frame1000004255-frame-badgebase2 {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    background-color: rgb(181, 50, 78);
  }
  
  .frame1000004255-text26 {
    color: rgba(234, 0, 51, 1);
    height: auto;
    font-size: 10px;
    text-align: center;
    font-family: Lato;
    font-weight: 500;
    line-height: 18px;
    font-stretch: normal;
    text-decoration: none;
  }
  </style>
  